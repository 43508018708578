<template>
  <div class="calendar-table">

    <table class="table">
      <thead>
        <tr class="thead__row--first">
          <th rowspan="2" class="first-column">
            Lieu
          </th>
          <th v-bind:colspan="available_dates.length" class="last-column">
            Sessions
          </th>
        </tr>
        <tr class="thead__row--last">
          <template v-for="date in available_dates">
            <th v-bind:key="`${date.year}_${date.month}`" class="first-column--monthes">
              {{map_months[date.month].label}} {{date.year}}
            </th>
          </template>
        </tr>
      </thead>

      <tbody>
        <template v-if="formated_sessions_list.length">
          <tr v-for="session_entry in formated_sessions_list" v-bind:key="session_entry.id">

            <td class="tbody__column--location"
                v-bind:class="{[type_text_color(5)]: session_entry.content[0].id === 30}">
              {{session_entry.name | firstLetterCapitalize}}
            </td>

            <template v-for="date in available_dates">
              <td v-bind:key="`${date.year}_${date.month}`" class="tbody__column--dates">

                <template v-for="(session,i) in session_entry.content">

                  <div v-bind:key="`session-${i}`"
                       v-if="isCurrentMonth(date.month, session.monthes.start_month)"
                       class="dates__month-session">

                    <a v-if="isSubscriptionAvailable(session)"
                       v-bind:href="toInscriptionRoute(session.reference)"
                       v-tooltip="{
                         content: `<strong>Formulaire d'inscription en ligne.</strong></br/><i>(Cliquez pour vous inscrire)</i>`,
                         placement:'top'
                       }"
                       class="session__link">
                      <font-awesome-icon icon="graduation-cap"
                                         size="2x"
                                         class="text-accent-300"/>
                      <div class="ml-4">
                        <p class="m-0 link--head">{{session.dates}}</p>
                        <p class="m-0 link--emphasize">
                          <template v-if="session.prix">
                            <span>
                              Prix : {{session.prix.toLocaleString()}} € HT <sup>(1)</sup>
                            </span>
                            <span class="pr-1">,</span>
                          </template>
                          <template v-if="duree_minutes || duree_jours">
                            <span>
                              Durée :
                            </span>
                            <span v-if="duree_minutes">
                              {{duree_minutes | minutesIntoHours}}
                            </span>
                            <span v-if="duree_jours"
                                  class="pl-0.5">
                              ({{duree_jours | displayJourString}})
                            </span>
                          </template>
                        </p>
                      </div>
                    </a>

                    <div v-else
                         v-on:click="openContactModal(session.id)"
                         v-tooltip="{
                           content: `<strong>Contactez-nous pour vous inscrire.</strong></br/><i>(Cliquez pour afficher les informations de contact)</i>`,
                           placement:'top'
                         }"
                         class="session__link">
                      <font-awesome-icon icon="phone-alt"
                                         size="2x"
                                         class="text-accent-300"/>
                      <div class="ml-4">
                        <p class="m-0 link--head">{{session.dates}}</p>
                        <p class="m-0 link--emphasize">
                          <template v-if="session.prix">
                            <span>
                              Prix : {{session.prix.toLocaleString()}} € HT <sup>(1)</sup>
                            </span>
                            <span class="pr-1">,</span>
                          </template>

                          <template v-if="duree_minutes || duree_jours">
                            <span>
                              Durée :
                            </span>
                            <span v-if="duree_minutes">
                              {{duree_minutes | minutesIntoHours}}
                            </span>
                            <span v-if="duree_jours"
                                  class="pl-0.5">
                              ({{duree_jours | displayJourString}})
                            </span>
                          </template>
                        </p>
                      </div>
                    </div>
                  </div>

                </template>

              </td>
            </template>
          </tr>
        </template>
        <tr v-else>
          <td class="bg-core-200 p-4 text-center" colspan="2">
            <h1>
              Aucune session disponible pour le moment
            </h1>
          </td>
        </tr>

      </tbody>
    </table>

  </div>
</template>

<script>
import {parse, compareAsc} from 'date-fns';

import {map_months, map_type_colors} from '@/assets/data/data_maps.js';

import helpers_filters from '@/mixin/helpers_filters';
import session_dates_functions from '@/mixin/session_dates_functions';

export default {
    mixins: [helpers_filters, session_dates_functions],
    props: {
        sessions: {
            type: [Array, Object],
            default: null
        },
        duree_minutes: {
            type: [String, Number]
        },
        duree_jours: {
            type: [String, Number]
        }
    },
    data() {
        return {
            map_months,
            map_type_colors
        };
    },
    computed: {
        formated_sessions_list() {
            const new_array = [];

            this.sessions.forEach(city => {
                const new_entries = [];

                city.sessions.forEach(session => {
                    const debut = `${session.dateDebut}`;
                    const fin = `${session.dateFin}`;

                    const date_start = parse(debut, 'dd/MM/yyyy', new Date());
                    const date_end = parse(fin, 'dd/MM/yyyy', new Date());

                    const start_month = date_start.getMonth();
                    const end_month = date_end.getMonth();

                    const dateLimiteInscription = session.dateLimiteInscription ? session.dateLimiteInscription : null;

                    const entry = {
                        id: session.sessionId,
                        reference: session.reference,
                        ville: session.sessionLieu ? session.sessionLieu.nom : city.name.nom,
                        dates: this.rangeOfDays(session),
                        monthes: {
                            start_month,
                            end_month
                        },
                        dateLimiteInscription,
                        prix: session.prixInscription
                    };
                    new_entries.push(entry);
                });

                new_array.push({
                    name: city.name.nom,
                    content: new_entries
                });
            });
            return new_array;
        },
        available_dates() {
            const list = [];

            this.sessions.forEach(({sessions}) => {
                sessions.forEach((session) => {
                    const debut = `${session.dateDebut}`;
                    const date_start = parse(debut, 'dd/MM/yyyy', new Date());

                    const new_entry = {
                        year: date_start.getFullYear(),
                        month: date_start.getMonth()
                    };
                    list.push(new_entry);
                });
            });

            const listRemoveDuplicate = list.reduce((accumalator, current) => {
                if (!accumalator.some(item => item.year === current.year && item.month === current.month)) {
                    accumalator.push(current);
                }
                return accumalator;
            }, []);

            const sorter = (a, b) => {
                if (a.year !== b.year) {
                    return a.year - b.year;
                } else {
                    return a.month - b.month;
                }
            };
            return listRemoveDuplicate.sort(sorter);
        },
    },
    methods: {
        openContactModal(id) {
            this.$root.$emit('open_modal_contact', id);
        },
        isSubscriptionAvailable(session) {
            let isAvailable = true;
            if (session && session.dateLimiteInscription) {
                const current_limit_date = this.parseToFrDate(session.dateLimiteInscription);

                isAvailable = compareAsc(current_limit_date, new Date()) == 1 ? true : false;
            }
            return isAvailable;
        },
        toInscriptionRoute(session_id) {
            return `https://${process.env.VUE_APP_HOST_INSCRIPTION}/${this.$route.params.fiche_id}/session/${session_id}`;
        },
        isCurrentMonth(table_month, current_month) {
            return table_month === current_month;
        },
        type_text_color(id) {
            return `text-${map_type_colors[id]} location--online`;
        },
    }
};
</script>

<style lang="scss" scoped>
table { border-collapse: separate; border-spacing: 0px; }
tr:first-child th:first-child { border-top-left-radius: 0.75rem; }
tr:first-child th:last-child { border-top-right-radius: 0.75rem; }
tr:last-child td:first-child { border-bottom-left-radius: 0.75rem; }
tr:last-child td:last-child { border-bottom-right-radius: 0.75rem; }

.calendar-table {
    @apply p-3;

    table {
        @apply table-fixed min-w-full my-2;

        thead {
            @apply text-center;
            tr {
                &.thead__row--first {
                    th {
                        &.first-column {
                            @apply w-64 px-4 py-3 border-core-400 border bg-primary-900 text-core-100;
                        }
                        &.last-column {
                            @apply bg-primary-900 text-core-100 px-4 py-3 border-core-400 border;
                        }
                    }
                }
                &.thead__row--last {
                    @apply bg-primary-800 text-core-100;
                    th {
                        &.first-column--monthes {
                            @apply px-4 py-3 border-core-400 border;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    &.tbody__column--location {
                        @apply text-center bg-core-100 border-core-300 border px-4 py-3 font-bold;
                        min-width: 15rem;

                        &:not(.location--online) {
                          @apply text-core-700;
                        }
                    }
                    &.tbody__column--dates {
                        @apply border border-core-300 leading-none;
                        min-width: 20rem;

                        .dates__month-session {
                            @apply border-b border-core-300 border-dashed  leading-relaxed;

                            &:last-of-type {
                                @apply border-none pb-0;
                            }

                            .session__link {
                                @apply cursor-pointer flex items-center p-4;
                                .link--head {
                                  @apply w-full tracking-tight font-bold
                                }

                                .link--emphasize {
                                    @apply w-full text-xs font-normal;
                                }

                                &:hover {
                                    @apply bg-accent-100;
                                  .link--head {
                                    @apply text-primary-900;
                                  }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .calendar-table__aside {
        @apply flex flex-wrap;
        ol {
            @apply list-decimal list-inside mb-2;
            li {
                @apply text-xs text-core-500 mb-1;
            }
        }
    }

}
</style>
