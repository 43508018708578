<template>
  <form ref="form_intra"
        v-on:submit.prevent="submit"
        class="text-primary-900 lg:p-4">
    <div class="w-full flex flex-wrap my-2">
      <div class="w-full flex space-x-4 mb-2">

        <template v-for="field in form_map">

          <div class="w-1/4" v-bind:key="`${field.name}`">
            <!-- {{$v.form[field.name]}} -->
            <input-box v-bind:name="`${field.name}`"
                       v-bind:label="`${field.label} ${$v.form[field.name].$params.hasOwnProperty('required') ? '*' : ''}`"
                       v-model.trim="form[field.name]"
                       v-on:input="setValidationOnVmodel($event)"
                       v-on:emit-name="setEmitName($event)"
                       v-bind:class="{'text-danger-500': $v.form[field.name].$error}"/>

            <div class="h-4 leading-none mt-1 w-full flex items-center">
              <transition name="fade-error">
                <span v-bind:key="$v.form[field.name].$error" class="leading-none text-xs text-danger-500">{{displayError($v.form[field.name])}}</span>
              </transition>
            </div>
          </div>

        </template>

      </div>

      <div class="w-full mb-6">
        <input-box name="entite"
                   label="Nom de l'entreprise"
                   placeholder="Saisir le nom de l'entreprise"
                   v-on:input="setValidationOnVmodel($event)"
                   v-on:emit-name="setEmitName($event)"
                   v-model="form.entite"/>
      </div>

      <div class="font-bold w-full mb-4">Période souhaitée *</div>
      <div class="flex w-full space-x-4 mb-2">
        <div class="w-72">
          <datepicker-box v-model="form.date.date_start"
                          label="Du"
                          label_icon="calendar-alt"
                          v-on:input="setValidationOnVmodel($event)"
                          v-on:emit-name="setEmitName($event)"
                          compare_type="date_start"
                          class="date-range"
                          v-bind:class="{'text-danger-500': $v.form.date.date_start.$error}"/>

          <div class="h-4 leading-none mt-1 w-full flex items-center">
            <transition name="fade-error">
              <span v-bind:key="$v.form.date.date_start.$error" class="leading-none text-xs text-danger-500">{{displayError($v.form.date.date_start)}}</span>
            </transition>
          </div>
        </div>

        <div class="w-72">
          <datepicker-box v-model="form.date.date_end"
                          label="Au"
                          label_icon="calendar-alt"
                          compare_type="date_end"
                          v-bind:compare_date="form.date.date_start"
                          class="date-range"
                          v-bind:class="{'text-danger-500': $v.form.date.date_end.$error}"/>

          <div class="h-4 leading-none mt-1 w-full flex items-center">
            <transition name="fade-error">
              <span v-bind:key="$v.form.date.date_end.$error" class="leading-none text-xs text-danger-500">{{displayError($v.form.date.date_end)}}</span>
            </transition>
          </div>
        </div>
      </div>

      <div class="w-full h-auto overflow-auto">
        <textarea-box name="message"
                      v-model="form.message"
                      rows="5"
                      v-bind:maxlength="$v.form.message.$params.maxLength.max"
                      label="Votre message (facultatif)"/>
        <div class="h-4 leading-none w-full flex items-center">
          <transition name="fade-error">
            <span v-bind:key="$v.form.message.$error" class="leading-none text-xs text-danger-500">{{displayError($v.form.message)}}</span>
          </transition>
        </div>
      </div>

      <div class="w-full inline-flex justify-end">
        <button type="submit" class="cursor-pointer w-64 bg-primary-900 py-3 px-5 hover:bg-primary-800 text-white font-bold rounded shadow text-center">
          Soumettre votre demande
        </button>
      </div>

      <div class="flex mt-4 w-full justify-end text-xs leading-none">
        <a href="#" class="hover:underline">Consulter les mentions légales relatives au RGPD</a>
      </div>

    </div>

    <modal-box v-if="modal_soumettre_intra"
               width_class="max-w-screen-md w-full"
               v-on:close="triggerClosingAction">

      <template #modal-header>
        <h1 class="text-xl text-core-700">
          Confirmation demande formation intra entreprise
        </h1>
      </template>

      <template #modal-body>
        <div v-if="!submitted">
          <p class="text-core-700 text-lg mb-4">Vous souhaitez organiser la formation <span class="text-primary-900">{{produit_reference}} - </span><span class="font-bold text-primary-900">{{titre}}</span> dans vos locaux</p>
          <p class="text-core-700 text-lg mb-4">Nos conseillers formation prendront contact avec vous dans les prochains jours afin de construire une proposition appropriée.</p>
          <p class="font-bold text-core-700 text-lg mb-4">Confirmez-vous votre demande ?</p>
        </div>
        <div v-if="submitted">
          <spinner
            v-if="submitLoading"
            sizes="w-20 h-20"
            thickness="border-8"/>
          <template v-if="!submitLoading">
            <div v-if="!submitSuccess" class="flex w-full justify-center text-danger-500 space-y-2 text-xl font-bold py-4 flex-wrap">
              <font-awesome-icon icon="times-circle"
                                 class="flex w-full text-6xl"/>
              <div class="flex w-full justify-center">
                Votre demande n'a pas pu être envoyée, merci de réessayer ultérieurement.
              </div>
            </div>
            <div v-if="submitSuccess" class="flex w-full justify-center text-green-500 space-y-2 text-xl font-bold py-4 flex-wrap">
              <font-awesome-icon icon="check-circle"
                                 class="flex w-full text-6xl"/>
              <div class="flex w-full justify-center">
                Votre demande a bien été envoyée
              </div>
            </div>
          </template>
        </div>

      </template>

      <template #modal-footer>
        <div class="w-full inline-flex space-x-4 justify-end">
          <div v-if="!submitted"
               class="cursor-pointer w-64 bg-primary-900 py-3 px-5 hover:bg-primary-800 text-white font-bold rounded shadow text-center"
               v-on:click="sendIntraRequest">
            Confirmer
          </div>
          <div v-on:click="triggerClosingAction" class="cursor-pointer w-64 bg-core-300 py-3 px-5 hover:bg-core-200 text-core-700 font-bold rounded shadow text-center">
            {{`${submitted ? 'Fermer': 'Annuler'}`}}
          </div>
        </div>
      </template>

    </modal-box>

  </form>
</template>

<script>
import {compareDesc} from 'date-fns';

import {helpers, required, email, maxLength} from 'vuelidate/lib/validators';

import inputBox from '@/components/utils/input-box.vue';
import textareaBox from '@/components/utils/textarea-box.vue';
import datepickerBox from '@/components/utils/datepicker-box.vue';
import modalBox from '@/components/utils/modal-box.vue';
import {API} from '@/http-common';
import spinner from '@/components/utils/spinner.vue';

const START_DATE = new Date();
const END_DATE = new Date();
END_DATE.setDate(START_DATE.getDate() + 1);

//+33 (0)1 53 85 82 54
const phoneExp = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
const isFrenchPhone = helpers.regex('isFrenchPhone', phoneExp);

const form_map = [
    {name: 'nom', label: 'Votre nom'},
    {name: 'prenom', label: 'Votre prénom'},
    {name: 'telephone', label: 'Votre numéro de téléphone'},
    {name: 'email', label: 'Votre adresse email'}
];

export default {
    components: {
        inputBox,
        textareaBox,
        datepickerBox,
        modalBox,
        spinner,
    },
    props: ['titre', 'reference', 'produit_reference'],
    data: () => ({
        form: {
            nom: '',
            prenom: '',
            email: '',
            telephone: '',
            entite: null,
            date: {
                date_start: START_DATE,
                date_end: END_DATE
            },
            message: null
        },
        form_map,
        modal_soumettre_intra: false,
        submitStatus: null,
        current_input: null,
        submitLoading: true,
        submitted: false,
        submitSuccess: true,
    }),
    validations: {
        form: {
            nom: {
                required
            },
            prenom: {
                required
            },
            telephone: {
                required,
                isFrenchPhone
            },
            email: {
                required,
                email
            },
            entite: {},
            date: {
                date_start: {
                    required
                },
                date_end: {
                    required
                }
            },
            message: {
                maxLength: maxLength(2000)
            }
        }
    },
    watch: {
        form: {
            deep: true,
            handler(newVal) {
                const comp = compareDesc(newVal.date.date_start, this.form.date.date_end);
                if (comp === -1) {
                    this.form.date.date_end = newVal.date.date_start;
                }
            }
        }
    },
    methods: {
        displayError(field) {
            const {$error, $params} = field;

            const hasValidator = (validator) => Object.prototype.hasOwnProperty.call($params, validator);

            const minLength = hasValidator('minLength') ? $params.minLength.min : null;
            const maxLength = hasValidator('maxLength') ? $params.maxLength.max : null;

            if ($error) {
                if (hasValidator('required') && !field['required']) {
                    return 'Ce champ est requis';
                } else if (hasValidator('minLength') && !field['minLength']) {
                    return `Ce champ doit contenir au minimum ${minLength} caractères`;
                } else if (hasValidator('maxLength') && !field['maxLength']) {
                    return `Ce champ doit contenir au maximum ${maxLength} caractères`;
                } else if (hasValidator('email') && !field['email']) {
                    return 'Ce champ doit être une adresse email valide';
                } else if (hasValidator('isFrenchPhone') && !field['isFrenchPhone']) {
                    return 'Ce champ doit être un numéro de téléphone valide';
                }
            }

        },
        setEmitName(input) {
            this.current_input = input;
        },
        setValidationOnVmodel(value) {
            const name = this.current_input;
            if (name) {
                this[name] = value;

                if (this.submitStatus) {
                    this.$v.form[name].$touch();
                }
            }
        },
        validateRequestIntra() {
            this.modal_soumettre_intra = true;
        },
        triggerClosingAction() {
            this.submitted = false;
            this.modal_soumettre_intra = false;
        },
        submit() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR';
            } else {
                this.submitStatus = 'PENDING';
                setTimeout(() => {
                    this.submitStatus = 'OK';
                    this.validateRequestIntra();
                }, 500);
            }
        },
        async sendIntraRequest() {
            this.submitted = true;
            this.submitLoading = true;

            try {
                const body = {...this.form, dateDebut: this.form.date.date_start, dateFin: this.form.date.date_end};
                await API.post(`catalogue/fiche/${this.reference}/session/intra-request`, body);
                this.submitSuccess = true;
            } catch (e) {
                this.submitSuccess = false;
            } finally {
                this.submitLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
  form {
    .date-range {
      @apply flex-nowrap items-center;

      /deep/ label {
        @apply inline-flex mx-4 mb-0;
        span {
          @apply leading-tight;
        }
      }
      /deep/ .vdp-datepicker.datepicker {
        @apply w-auto;
        .datepicker-input {
          @apply text-center hover:bg-core-100;
        }
      }
    }

    /deep/ .datepicker-box {
      @apply justify-around;
      .datepicker .datepicker-calendar {
        width: 300px;
      }
    }
  }
</style>
