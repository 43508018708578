<template>
  <div class="fiche-catalogue" v-bind:style="cssVars">

    <spinner v-if="loading"
             sizes="w-20 h-20"
             thickness="border-8"/>

    <div v-else
         class="w-full">

      <div class="fiche-catalogue__btn-back">
        <router-link v-bind:to="{name: 'catalogue'}"
                     class="btn-back__link">
          <font-awesome-icon class="link__icon"
                             icon="chevron-circle-left"
                             size="1x"/>
          <span class="link__text">Retour au catalogue</span>
        </router-link>
      </div>

      <div id="printable"
           class="fiche-catalogue__inner">

        <section class="inner__column column--start">
          <div class="column__content">

            <div ref="scrollable_start" class="content__inner">

              <div class="print-headline-wrapper">
                <div v-bind:data-title="fiche.titre"></div>
                <div v-bind:data-subtitle="fiche.sousTitre"></div>
              </div>

              <h3 class="content__title">Compétences</h3>

              <label-list label="Format de formation"
                          type="type_formation"
                          query_type="typesIds"
                          v-bind:list="mergeTypeFormation"
                          class="content__item"/>

              <label-list label="Métier"
                          type="metier"
                          query_type="metiersIds"
                          v-bind:list="convertMetiersLabels"
                          class="content__item"/>

              <label-list label="Famille de produits"
                          type="famille"
                          v-bind:query_type="queryTypesFamilies"
                          v-bind:list="fetchFamilies"
                          class="content__item"/>

              <label-list label="Public Concerné"
                          type="public_concerne"
                          query_type="publicConcernesIds"
                          v-bind:list="mergePublicConcernes"
                          bullet_list
                          class="content__item"/>

              <richtext-block v-if="fiche.prerequis"
                              v-bind:source="fiche.prerequis"
                              label="Pré-requis"
                              class="content__item"/>

              <richtext-block v-if="fiche.objectifs"
                              v-bind:source="fiche.objectifs"
                              label="Objectifs professionnels"
                              class="content__item "/>

              <richtext-block v-if="fiche.beneficesParticipants"
                              v-bind:source="fiche.beneficesParticipants"
                              label="Bénéfices Participants"
                              class="content__item"/>

            </div>
            <simplebar-scroll colors="bg-core-700" target="scrollable_start"/>

          </div>

        </section>

        <section class="inner__column column--center">

          <div class="column__content">

            <div ref="scrollable_middle" class="content__inner">

              <article class="content__main">

                <div class="main__headline">
                  <h1 v-if="fiche.titre" class="headline__title">
                    {{fiche.titre}}
                  </h1>
                  <h2 v-if="fiche.sousTitre" class="headline__subtitle">{{fiche.sousTitre}}</h2>
                </div>
                <div v-if="fiche.certifiant" class="ribbon ribbon-top-right">
                  <div class="ribbon__inner">
                    <font-awesome-icon icon="graduation-cap" size="1x"/>
                    <span class="pl-0.5">Certifiant</span>
                  </div>
                </div>

                <promotion-box v-if="fiche.messagePromotionnel"
                               v-bind:source="fiche.messagePromotionnel"/>

                <div class="flex my-3">
                  <div v-if="fiche.themesComplementaires.length"
                       class=" w-1/2">
                    <label-list type="thematique"
                                query_type="thematiquesIds"
                                label="Thématiques"
                                v-bind:list="fiche.themesComplementaires"/>
                  </div>

                  <div v-if="fiche.collections.length"
                       class="text-right w-1/2">
                    <label-list type="collection"
                                query_type="collectionsIds"
                                label="Collections"
                                v-bind:list="fiche.collections"
                                right/>
                  </div>
                </div>
                <div class="main__body">
                  <richtext-block v-if="fiche.preambule"
                                  v-bind:source="fiche.preambule"
                                  label="Préambule"
                                  class="content__item"/>

                  <richtext-block v-if="fiche.questionsTraitees"
                                  v-bind:source="fiche.questionsTraitees"
                                  label="Questions Traitées"
                                  class="content__item "/>

                  <richtext-block v-if="fiche.moyensPedagogiques"
                                  v-bind:source="fiche.moyensPedagogiques"
                                  label="Moyens Pédagogiques"
                                  class="content__item"/>

                  <richtext-block v-if="fiche.infosParticulieres"
                                  v-bind:source="fiche.infosParticulieres"
                                  label="Informations Particulières"
                                  class="content__item"/>

                  <richtext-block v-if="fiche.contenusLies"
                                  v-bind:source="fiche.contenusLies"
                                  label="Contenus Liés"
                                  class="content__item"/>
                </div>

              </article>

            </div>
            <simplebar-scroll colors="bg-core-700" target="scrollable_middle"/>

          </div>

        </section>

        <section class="inner__column column--end">

          <div class="column__content">

            <div ref="scrollable_end" class="content__inner">

              <div class="flex items-center justify-between">
                <h3 class="content__title content__title--owner">
                  <span>{{fiche.proprietaire}}</span>
                  <span>-</span>
                  <span>{{fiche.produitReference}}</span>
                </h3>
                <button v-on:click="() => printFiche()"
                        class="title__print-btn"
                        v-tooltip.left="'Imprimer'">
                  <font-awesome-icon icon="print" size="lg"/>
                </button>
              </div>

              <label-list v-if="fiche.tags.length"
                          type="tag"
                          query_type="tagsIds"
                          v-bind:list="fiche.tags"
                          class="content__item"/>
              <div v-if="fiche.ajouterVideo && fiche.urlVideo" class="content__video">
                <iframe class="video"
                        v-bind:src="getEmbedVideoUrl(fiche.urlVideo)"
                        frameborder="0"
                        allow="encrypted-media"
                        allowfullscreen></iframe>
              </div>
              <hr v-if="fiche.ajouterVideo && fiche.urlVideo" class="content__hr"/>
              <h3 class="content__title">Modalités</h3>
              <template v-if="fiche.programmableInter">
                <div class="session__selection">
                  <select-box v-model="current_ville"
                              is_update_method
                              v-bind:unique_entry_disabled="selectable_villes.length == 1"
                              name="ville"
                              label="Ville"
                              tracking="id"
                              v-bind:disabled="selectable_villes.length <= 1"
                              v-bind:placeholder="selectable_villes.length !== 0 ? 'Sélectionnez une ville': 'Aucune ville disponible'"
                              v-bind:categories="selectable_villes"
                              message_key="city"
                              v-on:input="updateValue"
                              v-bind:data-list="displayDataList('villes', selectable_villes)"/>
                </div>

                <div class="session__selection">
                  <select-box v-model="current_date"
                              is_update_method
                              v-bind:unique_entry_disabled="available_dates.length == 1"
                              name="date"
                              label="Date"
                              v-bind:placeholder="selectable_villes.length !== 0 ? 'Sélectionnez une date': 'Aucune date disponible'"
                              v-bind:disabled="available_dates.length <= 1"
                              v-bind:categories="selectable_dates"
                              v-on:input="updateValue"
                              v-bind:data-list="displayDataList('dates', list_sessions)"/>
                </div>
              </template>

              <div class="content__aside">

                <template v-if="fiche.programmableInter">
                  <div class="aside__calendar">
                    <button class="calendar__btn"
                            v-on:click.stop.prevent="modal_sessions_calendar = true"
                            v-bind:disabled="isCalendarDisabled">
                      <font-awesome-icon class="calendar__btn__icon"
                                         v-bind:icon="['far','calendar-alt']"
                                         size="4x"/>
                      <span class="calendar__btn__text">
                        Voir le calendrier
                      </span>
                    </button>
                  </div>
                </template>

                <div class="aside__info aside__info--half-size">

                  <p v-if="fiche.dureeMinutes" class="info">
                    <span class="info__label">
                      Durée&nbsp;:
                    </span>
                    <span class="info__value">
                      {{fiche.dureeMinutes | minutesIntoHours}}&nbsp;
                      <template v-if="fiche.dureeJours">
                        ({{fiche.dureeJours | displayJourString}})
                      </template>
                    </span>
                  </p>

                  <p v-if="fiche.prixCatalogue" class="info">
                    <span class="info__label">
                      Tarif&nbsp;:
                    </span>
                    <span class="info__value">
                      {{fiche.prixCatalogue}}&nbsp;€ HT
                    </span>
                  </p>
                </div>

                <div v-if="fiche.programmableIntra"
                     v-on:click.prevent="modal_formulaire_intra = true"
                     class="aside__intra__btn">
                  <font-awesome-icon class="intra__btn__icon"
                                     icon="users"/>
                  <span class="intra__btn__text">
                    Organisez&nbsp;cette&nbsp;formation dans&nbsp;vos&nbsp;locaux
                  </span>
                </div>

                <div v-if="Object.keys(displayPartenaires).length"
                     class="aside__info">
                  <hr class="content__hr"/>
                  <p class="info">
                    <span class="info__label">Partenaires :</span>
                    <span v-for="(partenaire,index) in displayPartenaires"
                          v-bind:key="partenaire.id"
                          class="info__value pr-0.5">
                      {{partenaire.nom}}{{// eslint-disable-next-line vue/no-parsing-error
                        index < Object.keys(displayPartenaires).length - 1 ? ',': ''}}
                    </span>
                  </p>
                </div>
              </div>

            </div>
            <simplebar-scroll colors="bg-core-700" target="scrollable_end"/>

            <div class="content__session">
              <button v-if="fiche.programmableInter && isSubscriptionAvailable"
                      class="session__validate"
                      v-bind:class="{'is-cncc': is_cncc}"
                      v-tooltip="isInscriptionDisabled ? { content: 'Aucune session sélectionnée'} : null"
                      v-bind:disabled="isInscriptionDisabled"
                      v-on:click="toInscriptionRoute">
                <a v-bind:href="!isInscriptionDisabled ? toInscriptionRoute : null" class="session__validate--link">
                  <font-awesome-icon class="session__validate__icon"
                                     icon="graduation-cap"
                                     size="lg"/>
                  <span class="session__validate__text">Inscription</span>
                </a>
              </button>

              <button v-if="fiche.programmableInter && !isSubscriptionAvailable"
                      class="session__validate"
                      v-on:click="onOpenModalContact(current_session_id)">
                <font-awesome-icon class="session__validate__icon"
                                   icon="phone-alt"
                                   size="lg"/>
                <span class="session__validate__text">Inscription</span>
              </button>
            </div>

          </div>

        </section>

      </div>
    </div>

    <modal-box v-if="modal_formulaire_intra"
               v-on:close="modal_formulaire_intra = false"
               v-bind:footer="false"
               v-bind:overflow_body="true"
               v-bind:width_class="`w-full ${authenticated ? 'max-w-screen-xl' : 'max-w-screen-lg'}`">

      <template #modal-header>
        <h1 v-if="authenticated" class="text-xl">Demande de formation intra entreprise</h1>
        <h1 v-else class="text-xl">Nous contacter</h1>
      </template>
      <template #modal-body>
        <formulaire-intra v-if="authenticated"
                          v-bind:titre="fiche.titre"
                          v-bind:reference="fiche.reference"
                          v-bind:produit_reference="fiche.produitReference"
        />
        <div v-else
             class="flex items-stretch justify-center flex-wrap -my-4">
          <div class="flex-1 text-center py-12 px-6"
               v-bind:class="{'border-r-1 border-core-300': email}"
               v-if="telephone">
            <div class="text-primary-800">
              <font-awesome-icon icon="phone-square-alt"
                                 size="4x"/>
            </div>
            <div class="text-primary-800 text-2xl font-bold mt-4">
              Téléphone
            </div>
            <div class="text-xl font-bold mt-4">
              {{telephone}}
            </div>
          </div>
          <div class="flex-1 text-center py-12 px-6"
               v-if="email">
            <div class="text-primary-800">
              <font-awesome-icon icon="envelope"
                                 size="4x"/>
            </div>
            <div class="text-primary-800 text-2xl font-bold mt-4">
              Adresse e-mail
            </div>
            <div class="text-xl font-bold mt-4">
              <a href="mailto:cnccservices.formation@cncc.fr" class="underline">
                {{email}}
              </a>
            </div>
          </div>
        </div>
      </template>
    </modal-box>

    <modal-box v-if="modal_sessions_calendar"
               v-on:close="closeSessionsCalendarModal"
               v-bind:footer="false"
               overflow_body>

      <template #modal-header>
        <h1 class="text-xl">Calendrier des formations</h1>
      </template>

      <template #modal-body>
        <calendar-table v-if="fiche.sessions"
                        v-bind:sessions="list_sessions"
                        v-bind:duree_minutes="fiche.dureeMinutes"
                        v-bind:duree_jours="fiche.dureeJours"/>
      </template>

      <template #modal-footer></template>

    </modal-box>

    <modal-box v-if="modal_contact_inscription"
               v-on:close="modal_contact_inscription = false"
               v-bind:footer="false"
               v-bind:width_class="'w-2/3'">

      <template #modal-header>
        <h1 class="text-xl">Contact</h1>
      </template>
      <template #modal-body>
        <contact-card v-bind:current_session="current_session_contact"/>
      </template>
    </modal-box>

  </div>
</template>

<script>
import {compareAsc} from 'date-fns';

import helpers_filters from '@/mixin/helpers_filters';
import session_dates_functions from '@/mixin/session_dates_functions';

import {map_metiers} from '@/assets/data/data_maps.js';

import spinner from '@/components/utils/spinner.vue';
import simplebarScroll from '@/components/utils/simplebar-scroll.vue';

import contactCard from '@/components/utils/contact-card.vue';
import modalBox from '@/components/utils/modal-box.vue';
import calendarTable from '@/components/utils/calendar-table.vue';
import labelList from '@/components/utils/label-list.vue';
import selectBox from '@/components/utils/select-box.vue';
import richtextBlock from '@/components/utils/richtext-block.vue';
import promotionBox from '@/components/utils/promotion-box.vue';

import formulaireIntra from '@/components/utils/formulaire-intra.vue';

import {API} from '@/http-common';

import {createNamespacedHelpers} from 'vuex';

const {
    mapState: mapStateAuth,
} = createNamespacedHelpers('auth');

const {mapState: mapStateObserver} = createNamespacedHelpers('observer');

export default {
    components: {
        spinner,
        simplebarScroll,
        modalBox,
        contactCard,
        calendarTable,
        labelList,
        selectBox,
        richtextBlock,
        promotionBox,
        formulaireIntra
    },
    mixins: [helpers_filters, session_dates_functions],
    props: ['fiche_id', 'preview_id', 'query'],
    data() {
        return {
            loading: false,
            modal_sessions_calendar: false,
            modal_formulaire_intra: false,
            modal_contact_inscription: false,
            fiche: [],
            errors: [],
            current_ville: [],
            current_date: [],
            current_session_id: null,
            session_contact_id: null,
            error_status_code: null,
            telephone: '01 44 77 82 82',
            email: 'cnccservices.formation@cncc.fr',
        };
    },
    computed: {
        ...mapStateAuth({
            authenticated: (state) => state.authenticated
        }),
        ...mapStateObserver({
            offset: (state) => state.offset,
        }),
        is_cncc() {
            return process.env.VUE_APP_PRIMARY_COLOR === 'cncc';
        },
        cssVars() {
            return {
                '--navbar-height': this.offset + 'px',
            };
        },
        fetchFamilies() {
            const formation_continue = this.fiche.familleFormationContinue && {id: 1, label: 'Formation professionnelle'};
            const stage = this.fiche.familleStage && {id:2, label: 'Stage'};

            const families = [];
            [formation_continue, stage].forEach((family) => {
                family && families.push(family);
            });
            return families;
        },
        queryTypesFamilies() {
            return {1: 'familleFormationContinue', 2: 'familleStage'};
        },
        list_sessions() {
            return this.fiche.sessions ? this.listSessions(this.fiche.sessions) : [];
        },
        isSubscriptionAvailable() {
            let isAvailable = true;

            const current_session = this.fiche.sessions.filter((session) => {
                return session.sessionId === this.current_session_id;
            });

            if (current_session[0] && Object.prototype.hasOwnProperty.call(current_session[0], 'dateLimiteInscription')) {
                const current_limit_date = this.parseToFrDate(current_session[0].dateLimiteInscription);

                isAvailable = compareAsc(current_limit_date, new Date()) == 1 ? true : false;
            }
            return isAvailable;
        },
        toInscriptionRoute() {
            const current_session = this.fiche.sessions.find((session) => {
                return session.sessionId === this.current_session_id;
            });
            return `https://${process.env.VUE_APP_HOST_INSCRIPTION}/${this.fiche_id}/session/${current_session ? current_session.reference : ''}`;
        },
        isCalendarDisabled() {
            return this.fiche.sessions && this.fiche.sessions.length <= 1;
        },
        isInscriptionDisabled() {
            return !this.current_ville.length || !this.current_date.length;
        },
        available_dates() {
            const dates = [];
            if (this.current_ville.length) {
                const current_id = this.current_ville[0].id;
                const payload = this.list_sessions.filter(({name}) => {
                    return name.id === current_id;
                });
                dates.push(...payload[0].dates);
            }
            return dates;
        },
        selectable_villes() {
            const array_villes = this.list_sessions.map(({name}) => {
                return {id: name.id, label: name.nom, reference: name.reference};
            });
            return array_villes;
        },
        selectable_dates() {
            const array_selection = [];
            if (this.available_dates.length) {
                const dates_list = this.available_dates;
                dates_list.forEach((date) => {
                    const entry = {
                        id: date.id,
                        label: `${date.label}`,
                        $isDisabled: false
                    };
                    array_selection.push(entry);
                });
            }
            return array_selection;
        },
        current_session_contact() {
            return this.fiche.sessions.filter((session) => session.sessionId === this.session_contact_id);
        },
        convertMetiersLabels() {
            return this.fiche.metiers.map(metier => {
                return {id: metier.id, label: map_metiers[metier.label].label};
            });
        },
        mergePublicConcernes() {
            const publics = this.fiche.publicConcernes;
            const autres = this.fiche.autrePublic;
            const merge = autres ? [...publics, {id: 99, label: autres, isAutre: true}] : publics;
            return merge;
        },
        mergeTypeFormation() {
            const types = [this.fiche.type];

            if (this.hasSessionClasseVirtuelle(this.fiche)) {
                types.push({id: 5, label: 'Classe virtuelle'});
            }

            return types;

        },
        displayPartenaires() {
            const available_partenaires = this.fiche.sessions.map((session) => {
                const coorganisateurs = session.coorganisateurs.map((item) => {
                    return {
                        id: item.clientId,
                        nom: item.nom
                    };
                });
                return {
                    session_id: session.sessionId,
                    coorganisateurs,
                    commanditaires: session.commanditaires,
                };
            });

            const current_session_partenaires = available_partenaires.filter((session_partenaire) => {
                return session_partenaire.session_id === this.current_session_id;
            });

            const merge_partenaires = current_session_partenaires && current_session_partenaires[0] ? [
                ...current_session_partenaires[0].coorganisateurs,
                ...current_session_partenaires[0].commanditaires
            ] : [];

            function getUniqueListBy(key, arr) {
                return [...new Map(arr.map(item => [item[key], item])).values()];
            }
            const unique_partenaires = getUniqueListBy('id', merge_partenaires);

            function sortByNames(a, b) {
                a = a.nom.toLowerCase();
                b = b.nom.toLowerCase();

                return a > b ? 1 : b > a ? -1 : 0;
            }
            return unique_partenaires.sort(sortByNames);
        }
    },
    watch: {
        selectable_villes: 'initCurrentVille',
        current_ville(newVal) {
            if (newVal.length) {
                const data = {
                    name: 'date',
                    value: this.selectable_dates[0]
                };
                this.updateValue(data);
            }
        }
    },
    created() {
        this.fetchCurrentFiche();
    },
    mounted() {
        this.$root.$on('open_modal_contact', (id) => {
            this.onOpenModalContact(id);
        });
    },
    methods: {
        printFiche() {
            this.$htmlToPaper('printable');
        },
        fetchCurrentFiche() {
            this.loading = true;
            if (this.fiche_id) {

                API.get(`catalogue/fiche/${this.fiche_id}`)
                    .then((response) => {
                        this.fiche = response.data;
                        this.loading = false;
                    })
                    .catch((e) => {
                        this.errors.push(e);
                    });
            } else {
                API.get(`catalogue/fiche/preview/${this.preview_id}`)
                    .then((response) => {
                        this.fiche = response.data;
                        this.loading = false;
                    })
                    .catch((e) => {
                        this.errors.push(e);
                    });
            }
        },
        initCurrentVille() {
            if (this.selectable_villes.length) {
                const query = this.$route.query.search;
                const index = query ? this.selectable_villes.findIndex(ville => ville.reference === query) : 0;
                this.current_ville = [this.selectable_villes[index !== -1 ? index : 0]];
            }
        },
        updateValue(data) {
            const target = `current_${data.name}`;
            this[target] = [data.value];
            this.current_session_id = target === 'current_date' ? data.value.id : null;
        },
        onOpenModalContact(id) {
            this.session_contact_id = id;
            this.modal_contact_inscription = true;
        },
        getEmbedVideoUrl(url) {
            const regExpYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const regExpVimeo = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;

            if (url.match(regExpYoutube)) {

                const match = url.match(regExpYoutube);
                const video_id = match && match[2].length === 11
                    ? match[2]
                    : null;
                return `https://www.youtube.com/embed/${video_id}`;

            } else if (url.match(regExpVimeo)) {

                const match = url.match(regExpVimeo);
                const video_id = match && match.length == 7
                    ? match[6]
                    : null;
                return `https://player.vimeo.com/video/${video_id}`;

            } else {
                return null;
            }
        },
        closeSessionsCalendarModal() {
            if (!this.modal_contact_inscription) {
                this.modal_sessions_calendar = false;
            }
        },
        hasSessionClasseVirtuelle(fiche) {
            return fiche.type && fiche.type.id === 1 && fiche.sessions && fiche.sessions.some(session => session.classeVirtuelle);
        },
        displayDataList(type, data) {
            if (type === 'villes') {
                const list = data.map((item) => {
                    return item.label;
                });
                return list.join(', ');
            } else if (type === 'dates') {
                const list = data.map((item) => {
                    const dates = item.dates.map((date, index) => {
                        return date.label;
                    });
                    return `${dates.join(' - ')} (${item.name.nom})`;
                });
                return list.join(', ');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';
.fiche-catalogue {
    @apply flex items-center justify-center h-full;

    .fiche-catalogue__btn-back {
        @apply fixed flex items-center justify-center bg-white rounded-lg uppercase font-bold text-sm leading-none shadow-sm hover:text-primary-800 focus:text-primary-800 hover:shadow transition duration-150 ease-out;

        top: calc(var(--navbar-height) + 0.5rem);
        left: 0.75rem;

        .btn-back__link {
          @apply pt-1.5 pb-1 px-3 ;

          .link__icon {}
          .link__text {
              @apply ml-2;
          }
        }
    }

    .fiche-catalogue__inner {
        height: calc(100vh - var(--navbar-height) - 5rem);
        @apply flex items-stretch justify-around w-11/12 mx-auto;

        .inner__column {
            @apply relative;

            /deep/ .simplebar-content {
                @apply h-full;
            }

            /deep/ .simplebar-scrollbar::before {
                opacity: 0.1;
                @apply bg-core-600 transition ease-out duration-300;
            }
            &:hover /deep/ .simplebar-scrollbar::before {
                @apply opacity-25;
            }

            .column__content {
                @apply relative mx-auto h-full;

                .content__inner {
                    @apply h-full overflow-y-auto rounded-lg shadow bg-white;

                    .content__title {
                        @apply mb-8;
                        &.content__title--owner {
                          @apply mb-0 text-lg;
                        }
                    }

                    button.title__print-btn {
                      @apply hover:text-primary-900;
                    }

                    .content__video {
                      @apply relative overflow-hidden w-full bg-core-300;

                      /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
                      padding-top: 56.25%;
                      .video {
                        /* Then style the iframe to fit in the container div with full height and width */
                        @apply absolute inset-0 w-full h-full;
                      }
                    }

                    .content__item {
                        @apply mb-6;
                    }
                    .content__hr {
                        @apply my-4 mx-1 border-core-300;
                    }
                }
            }

            &.column--start {
                @apply w-1/4 h-full;

                .column__content {
                    @apply w-11/12;

                    .content__inner {
                        @apply bg-white py-6 px-8;
                    }
                }
            }

            &.column--center {
                @apply w-2/4 h-full shadow-xl;
                .column__content {
                    .content__inner {
                        @apply bg-white py-8;
                        .content__img {
                            @apply w-full object-cover bg-core-600 h-72;

                            &.content__img--default {
                                @apply h-72;
                                @include placeholder-img();
                            }
                        }

                        .content__main {
                            @apply flex flex-col max-w-full pr-12 pl-10;

                            .main__headline {
                                @apply flex flex-col mb-3 w-11/12;

                                .headline__title {
                                    @apply text-primary-800 leading-none text-2xl;
                                    word-break: break-word;
                                }

                                .headline__subtitle {
                                    @apply text-core-500 font-bold text-lg leading-tight my-3;
                                    word-break: break-word;
                                }
                            }

                            .main__time {
                              @apply flex flex-wrap justify-start my-3 text-accent-600 italic text-sm leading-none;

                              .time__icon {
                                @apply mr-1;
                              }

                              .time__text {}
                            }

                            .main__tags {
                              @apply flex flex-wrap items-center;
                            }

                            .main__body {
                                @apply my-4;
                            }

                        }

                    }
                }
            }

            &.column--end {
                @apply w-1/4 h-full;

                .column__content {
                    @apply w-11/12;

                    /deep/ .multiselect__content {
                        @apply w-full;
                    }

                    .simplebar-scroll {
                        margin-bottom: calc(0.75rem + 4rem);
                    }

                    .content__inner {
                        @apply bg-white py-6 px-8 mb-8 rounded-b-none;
                        height: calc(100% - 4rem);

                        .content__aside {
                            @apply flex flex-wrap items-center mt-6;

                            .aside__calendar {
                                @apply w-1/2;

                                .calendar__btn {
                                    @apply cursor-pointer bg-core-100 flex flex-wrap justify-center py-2 mr-2 rounded transition ease-out duration-150 text-core-700 border hover:shadow hover:text-primary-900 bg-white disabled:opacity-25 disabled:text-core-700 disabled:cursor-not-allowed;
                                    &:active {
                                        @apply shadow-inner text-core-700;
                                    }

                                    .calendar__btn__icon {
                                        @apply p-2;
                                    }

                                    .calendar__btn__text {
                                        @apply text-sm my-2 px-2 text-center inline-block w-full leading-none;
                                    }
                                }
                            }

                            .aside__info {
                                @apply w-full;

                                &.aside__info--half-size {
                                  @apply w-1/2;
                                }

                                .info {
                                    @apply flex flex-wrap py-0.5 px-1 text-sm;
                                    .info__label {
                                        @apply text-core-700 font-bold mr-2;
                                    }
                                    .info__value {
                                      @apply text-accent-600;
                                    }
                                }

                            }

                            .aside__intra__btn {
                                @apply cursor-pointer w-full flex justify-center items-center bg-core-600 text-core-100 px-4 py-3 rounded mt-5 shadow;

                                &:hover {
                                    @apply bg-primary-900;
                                }

                                .intra__btn__icon {
                                    @apply w-8 pr-3 flex;
                                }
                                .intra__btn__text {
                                    @apply w-auto flex leading-none text-sm font-bold text-center;
                                }
                            }

                        }
                      }
                      .session__selection {
                        @apply w-full mb-3;
                      }
                      .content__session {
                        @apply absolute bottom-0 shadow-md left-0 right-0 w-full flex flex-wrap items-end h-16;

                        .session__validate {
                          @apply w-full h-full uppercase text-center font-bold text-core-100 bg-primary-900 flex justify-center items-center self-end rounded-b shadow leading-none;

                          .session__validate--link {
                              @apply flex items-center justify-center w-full h-full p-4;
                          }

                          &:hover {
                            @apply text-white bg-primary-800;
                          }
                          &:disabled {
                            @apply opacity-75 bg-core-500 cursor-not-allowed;
                          }
                          .session__validate__icon {
                            @apply inline-flex mb-1;
                          }
                          .session__validate__text {
                            @apply inline-flex mx-5;
                          }

                          &.is-cncc{
                            @apply text-white bg-core-900;

                            &:hover {
                              @apply bg-secondary-600;
                            }
                          }
                        }
                      }
                }
            }
        }
    }
}
</style>
