<template>
  <div class="promotion-box">
    <font-awesome-icon v-if="icon"
                       v-bind:icon="icon"
                       class="promotion-box__icon"
                       size="lg"/>
    <span class="promotion-box__text" v-html="source"></span>
  </div>
</template>
<script>
export default {
    props: {
        icon: {
            type: [String, Array],
        },
        source: {
            type: String,
        }
    }
};
</script>
<style lang="scss" scoped>
.promotion-box {
  @apply my-4 flex items-center border-l-4 border-primary-800 text-accent-800 shadow bg-accent-100 p-6 rounded;
  word-break: break-word;

  .promotion-box__icon {
    @apply mr-2;
  }
  .promotion-box__text {
    @apply pl-2 leading-tight;
  }
}
</style>
